// Import store dependencies
import { store } from './store';
import { Provider } from 'react-redux';

// Import layouts
import { MainLayout } from './layouts/MainLayout';

// Import Google authentication wrapper
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function App() {

  return (
    <Provider store={store}> 
      <GoogleOAuthProvider clientId="664838859370-97e1ahrec8932js8o2s0omgnl5274ctd.apps.googleusercontent.com" >
        {<MainLayout />}
      </GoogleOAuthProvider>
    </Provider>
  );
}

