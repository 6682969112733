// React hooks
import { useEffect, useLayoutEffect, useState } from "react";

// Project components
import { RadioSquare } from "../components/RadioSquare";

// Redux store
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { 
  selectRadioStatus,
  selectAllRadios,
  selectSearchQuery,
  selectCountryCode,
  getUserCountryCode,
  fetchRadiosByNameAndCountry,
  incrementOffset,
  resetOffset 
} from "../store/radios";

// Infinite scrolling loader
import useOnScreen from "../hooks/useOnScreen";

// Component styles
import "../styles/RadiosList.css";

// Logging
import { logError } from "../services/appInsightsService";

export const RadiosList = () => {
  // Get state of store properties
  const dispatch = useDispatch<any>();
  const status = useSelector(selectRadioStatus);
  const nameQuery = useSelector(selectSearchQuery);
  const countryCode = useSelector(selectCountryCode);
  const currentStoreRadios = useSelector(selectAllRadios);

  // Component state hooks
  const [localStatus, setLocalStatus] = useState<string>("");
  const [localNameQuery, setLocalNameQuery] = useState<string>("");
  const [localCountryCode, setLocalCountryCode] = useState<string>(getUserCountryCode());
  const [radiosList, setRadiosList] = useState<any>([]);
  const [readyToLoadMore, setReadyToLoadMore] = useState<boolean>(false);
  const [loadingMoreRadios, setLoadingMoreRadios] = useState<boolean>(false);

  // Infinite scrolling dependencies
  const { 
    measureRef,
    isIntersecting,
    observer 
  }: { measureRef: any, isIntersecting: boolean, observer: IntersectionObserver | undefined } = useOnScreen();

  const getMoreRadios = () => {
    dispatch(fetchRadiosByNameAndCountry())
      .then(unwrapResult)
      .then((result: any) => {
        setRadiosList([...radiosList , ...result]);
        dispatch(incrementOffset());
        setReadyToLoadMore(true);
        setLoadingMoreRadios(false);
      })
      .catch((error: Error) => {
        logError(error, "Failed to load more radio stations.")
      });
  };

  useEffect(() => {
    setRadiosList(currentStoreRadios);
    setReadyToLoadMore(true);
  }, []);

  // Runs when end of list is reached when using infinite scroll
  useLayoutEffect(() => {
    if (isIntersecting && readyToLoadMore) {
      setLoadingMoreRadios(true);
      getMoreRadios();
      observer?.disconnect();
    }
  }, [isIntersecting, readyToLoadMore]);

  // Runs when status changes
  useEffect(() => {
    if(status !== localStatus) {
      setLocalStatus(status);
      if (status === "idle") {
        if(radiosList.length === 0) {
          dispatch(resetOffset());
          getMoreRadios();
        }
      }
    }
  }, [status, dispatch]);

  // Runs when nameQuery changes
  useEffect(() => {
    if (localNameQuery !== nameQuery) {
      setLocalNameQuery(nameQuery);
      dispatch(resetOffset());
      dispatch(fetchRadiosByNameAndCountry())
      .then(unwrapResult)
      .then((result: any) => {
        setRadiosList(result);
        dispatch(incrementOffset());
      })
      .catch((error: Error) => {
        logError(error, "Error when attempting to load radios after updating search text.")
      });
    }
  }, [nameQuery, dispatch]);

  // Runs when countryCode changes
  useEffect(() => {
    console.log("CountryCode", countryCode);
    if (localCountryCode !== countryCode) {
      setLocalCountryCode(countryCode);
      dispatch(resetOffset());
      dispatch(fetchRadiosByNameAndCountry())
      .then(unwrapResult)
      .then((result: any) => {
        setRadiosList(result);
        dispatch(incrementOffset());
      })
      .catch((error: Error) => {
        logError(error, "Error when attempting to load radios after updating scountry filter.")
      });
    }
  }, [countryCode, dispatch]);

  if ((radiosList.length === 0 && !nameQuery) || (status === "loading" && !loadingMoreRadios)) {
    return (
    <div className="flex justify-center items-center h-screen">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...
        </span>
      </div>
    </div>
    );
  }

  return (
    <>
      <div className="radios-grid">
        {radiosList.map((radio: any, index: number) => {
          if (radiosList.length === index + 1) {
            return (
              <div className="square mobile-flex-item" key={index} ref={measureRef}>
                <RadioSquare radio={radio} />
              </div>
            );
          } 
          else {
            return (
              <div className="square mobile-flex-item" key={index}>
                <RadioSquare radio={radio} />
              </div>
            );
          }
        })}
      </div>
      {loadingMoreRadios && (
        <div className="flex justify-center items-center mt-16 w-full">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...
            </span>
          </div>
        </div>
      )}
    </>
  );
};