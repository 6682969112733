// React hooks
import { useEffect, useState } from "react";

// Router dependencies
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";

// Icons
import { faHeadphones, faStar, faTowerBroadcast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NavBarProps {
    newItemSelected: () => void;
}

export const NavBar = ({newItemSelected}: NavBarProps) => {
    const [ currentPageIndex, setCurrentPageIndex ] = useState(0);
    const location = useLocation();

    // When url changes
    useEffect(() => {
        // Get page path
        const path = location.pathname;
        // Set current page index
        switch(path) {
        case "/":
            setCurrentPageIndex(0);
            break;
        case "/favorites":
            setCurrentPageIndex(1);
            break;
        case "/about":
            setCurrentPageIndex(2);
            break;
        default:
            setCurrentPageIndex(0);
        }
    }, [location.pathname])

    const onNavBarItemClick = (index: number) => {
        setCurrentPageIndex(index);
        if(index != currentPageIndex) newItemSelected();
    }

    return (
        <>
            <Link 
                to="/" 
                className={currentPageIndex == 0 ? "nav-bar-item-selected py-2" : "nav-bar-item py-2"}
                onClick={() => onNavBarItemClick(0)}>
                    <FontAwesomeIcon 
                        icon={faTowerBroadcast} 
                        className={currentPageIndex == 0 ? "link-icon-selected" : "link-icon"}
                    />
                    Radio
            </Link>
            <Link 
                to="/favorites" 
                className={currentPageIndex == 1 ? "nav-bar-item-selected py-2" : "nav-bar-item py-2"}
                onClick={() => onNavBarItemClick(1)}>
                    <FontAwesomeIcon 
                        icon={faStar} 
                        className={currentPageIndex == 1 ? "link-icon-selected" : "link-icon"}
                    />
                    Favorites
            </Link>
            <Link 
                to="#"
                className="link-disabled nav-bar-item-disabled py-2">
                    <FontAwesomeIcon 
                        icon={faHeadphones} 
                        className="link-icon-disabled" 
                    />
                    Podcasts 
                <div className="soon-container">
                    <span className="soon">soon</span>
                </div>
            </Link>
        </>
    )

}