// React dependencies
import { ReactNode, useEffect } from 'react';

// Store dependencies
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { 
    fetchFavoriteRadios,
    selectUserId,
    selectUserSource,
    updateUserId,
    updateUserSource,
    setFavoriteRadios 
} from '../store/radios';

// Logging dependencies
import { logError } from '../services/appInsightsService';

interface FavoriteRadiosSyncProps {
    children: ReactNode;
}

export const FavoriteRadiosSync = ({ children }: FavoriteRadiosSyncProps) => {
    const dispatch = useDispatch<any>();
    const userId = useSelector(selectUserId);
    const userSource = useSelector(selectUserSource);

    // Fetch favorite radios on initial load
    useEffect(() => {
        const localStorageUser = localStorage.getItem('myRadioGlobalUser');
        if (localStorageUser) {
            // User data is stored on device local storage
            // Update store with user data from local storage
            dispatch(updateUserId(JSON.parse(localStorageUser).id));
            // ToDo: Make this dynamic when we have more login option
            dispatch(updateUserSource("google"));
        }
        
        // If user data in store, fetch user favorite radios and add to store
        if(userId && userSource){
            dispatch(fetchFavoriteRadios())
            .then(unwrapResult)
            .then((data: any) => {
                // Set user's favorite radios in store
                dispatch(setFavoriteRadios(data));
            })
            .catch((error: any) => {
                // Log error
                logError(error);
                console.log('Error fetching favorite radios from initial load: ', error);
            });
        }
    }, [dispatch, userId]);
  
    return (
        <>
            {children}    
        </>
    );
}