export const About = () => {
  return (
    <div>
      <h1>Welcome to Radio Global</h1>
      <br />
      <p>
        Radio Global is an online streaming app that allows you to listen to
        radio stations from all over the world. With Radio Global, you can
        discover new music and cultures from the comfort of your own home.
      </p>
      <p>
        Our app features a wide variety of radio stations, including music,
        news, sports, and more. You can search for stations by name or by
        country, and save your favorite stations for easy access.
      </p>
      <p>
        Radio Global is built using JavaScript, npm, TypeScript, and React. Our
        team is dedicated to providing the best possible streaming experience
        for our users, and we are constantly working to improve our app.
      </p>
      <br />
      <p>
        Thank you for choosing Radio Global. We hope you enjoy using our app!
      </p>
    </div>
  );
};