// React hooks
import { useEffect, useState } from "react";

// Google auth logic
import { useGoogleLogin } from '@react-oauth/google';
import { getUserGoogleProfile } from "../services/googleAuth"

// Styles
import "../styles/Favorites.css"
import "../styles/Radios.css"

// Logging
import { pageView } from "../services/appInsightsService";

// Project components
import { RadiosListFavorites } from "../lists/RadiosListFavorites";
import { UserAvatar } from "../components/UserAvatar";

// Redux store
import { selectUserId, selectUserSource, updateUserId, updateUserSource } from "../store/radios";
import { useDispatch, useSelector } from "react-redux";

export const Favorites = () => {
    const dispatch = useDispatch();
    const userSource = useSelector(selectUserSource);

    const [ userAuthToken, setUserAuthToken ] = useState<any>(null);
    const [ userDetails, setUserDetails ] = useState<any>(null);

    useEffect(() => {
        // Log page view to app insights
        pageView("Radios");

        // ToDo: Make this dynamic when we add more auth sources
        if(!userSource) dispatch(updateUserSource("google"))

        // Check if user has already logged in via this device in the past
        const localStorageUser = localStorage.getItem('myRadioGlobalUser');
        if (localStorageUser) {
            dispatch(updateUserId(JSON.parse(localStorageUser).id));
            setUserDetails(JSON.parse(localStorageUser));
        }
    }, []);

    useEffect(() => {
        if (userAuthToken) {            
            // Get user profile information from Google
            // Use the fetch api
            getUserGoogleProfile(userAuthToken)
            .then(data => {
                localStorage.setItem('myRadioGlobalUser', JSON.stringify(data));
                dispatch(updateUserId(data.id));
                setUserDetails(data);
            })
        }
    }, [userAuthToken]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse: any) => setUserAuthToken(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    if (!userDetails) {
        return (
            <div className="flex flex-col items-center">
                <div className="flex flex-col gap-4 w-4/5 sm:w-3/5 p-6 rounded-md bg-neutral-50 items-center oauth-card">
                    <h2>Login</h2>
                    <p>Connect your Google account to save your favorite radio stations!</p>
                    <button type="button" onClick={() => login()} className="login-with-google-btn" >
                        Sign in with Google
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between pl-4 ">
                <span className="radios-title">Your favorite radios</span>
                {userDetails.picture && (
                    <UserAvatar userImage={userDetails.picture} userDisplayName={userDetails.name}/>
                )}
            </div>
            <RadiosListFavorites />
        </div>
    );
};