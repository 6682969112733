import { createAsyncThunk, createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { getRadios, getRadiosByIds, getRadiosFromUserFavorites } from "../services/radioGlobalApi";

import { allCountryCodes } from "../staticData/allCountryCodes";
import { countriesMapping } from "../staticData/countriesMapping";

// Interfaces
import { Radio } from "../interfaces/Radio"

interface RadioState {
    radioList: Radio[],
    favoriteRadioList: Radio[],
    userId: string,
    userSource: string,
    countryCode: string,
    nameQuery: string,
    status: "idle" | "loading" | "failed" | "succeeded";
    error: string | null | undefined;
    offset: number;
}

export const getUserCountryCode = () => {
    let countryFilter = window.location.pathname.split('/')[1];
    let countryCode = countriesMapping.find((country) => country.name.toLowerCase().replaceAll(" ", "") === countryFilter.toLowerCase().replaceAll(" ", ""))?.iso_3166_1 || ""

    if (countryCode) {
        return countryCode;
    }
    
    // Update url path (remove invalid country path)
    window.history.pushState({}, "", "/");

    if(allCountryCodes.includes(Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1])) {
        return Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1];
    }
    else {
        return "";
    }
}

const initialState: RadioState = {
    radioList: [],
    favoriteRadioList: [],
    userId: "",
    userSource: "",
    countryCode: getUserCountryCode(),
    nameQuery: "",
    status: "idle",
    error: null,
    offset: 0
};

const pageSize: number = 43;

export const fetchRadiosByNameAndCountry = createAsyncThunk(
    "radios/fetchRadiosByNameAndCountry",
    async (params, {getState}) => {
        const { radios } = getState() as RootState;
        // When using radio browser api (open source public api)
        //const response = await getRadiosByNameAndCountry(radios.countryCode, radios.nameQuery);

        //console.log("offset", radios.offset);
        
        // When using our own api
        const response = await getRadios(radios.nameQuery, radios.countryCode, pageSize.toString(), radios.offset.toString());

        //console.log("response from store", response.length);

        return response
        // map the response to the Radio interface
        .map((radio: Radio) => {
            return {
                name: radio.name,
                country: radio.country,
                countryCode: radio.countryCode,
                state: radio.state,
                id: radio.id,
                url: radio.url,
                urlResolved: radio.urlResolved,
                clickCount: radio.clickCount,
                favicon: radio.favicon
            };
        })
        // sort by clickCount in descending order
        //.sort((a: Radio, b: Radio) => b.clickCount - a.clickCount)
    }
);

export const fetchFavoriteRadios = createAsyncThunk(
    "radios/fetchFavoriteRadios",
    async (params, {getState}) => {
        const { radios } = getState() as RootState;

        console.log("fetchFavoriteRadios", radios.userId, radios.userSource);

        // If user is not logged in, return empty array
        if (!radios.userId || !radios.userSource) {
            return [];
        }

        // Fetch user favorite radios from database
        const userFavorites = await getRadiosFromUserFavorites(radios.userId, radios.userSource)

        // Fetch radios by ids
        return await getRadiosByIds(userFavorites);
    }
);

const radiosSlice = createSlice({
    name: "radios",
    initialState,
    reducers: {
        // reducer for updating the nameQuery
        updateNameQuery: (state, action: PayloadAction<string>) => {
            state.nameQuery = action.payload;
        },
        // reducer for updating the countryCode
        updateCountryCode: (state, action: PayloadAction<string>) => {
            state.countryCode = action.payload;
        },
        incrementOffset: (state) => {
            state.offset += pageSize;
        },
        resetOffset: (state) => {
            state.offset = 0;
        },
        updateUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        updateUserSource: (state, action: PayloadAction<string>) => {
            state.userSource = action.payload;
        },
        addRadioToFavorite: (state, action: PayloadAction<Radio>) => {
            if(!state.favoriteRadioList.find((radio) => radio.id === action.payload.id)) {
                state.favoriteRadioList.push(action.payload);
            }
        },
        removeRadioFromFavorite: (state, action: PayloadAction<string>) => {
            console.log("removeRadioFromFavorite", action.payload);
            state.favoriteRadioList = state.favoriteRadioList.filter((radio) => radio.id !== action.payload);
            console.log("removeRadioFromFavorite", state.favoriteRadioList);
        },
        setFavoriteRadios: (state, action: PayloadAction<Radio[]>) => {
            state.favoriteRadioList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchRadiosByNameAndCountry.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchRadiosByNameAndCountry.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.radioList = action.payload;
        })
        .addCase(fetchRadiosByNameAndCountry.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        });
    }
});

export const selectAllRadios = (state: RootState) => state.radios.radioList;
export const selectFavoriteRadios = (state: RootState) => state.radios.favoriteRadioList;
//export const selectRadioById = (state: RadioState, radioId: number) =>
//  state.radioList.find((radio) => radio.id === radioId);
export const selectUserId = (state: RootState) => state.radios.userId;
export const selectUserSource = (state: RootState) => state.radios.userSource;
export const selectRadioStatus = (state: RootState) => state.radios.status;
export const selectCountryCode = (state: RootState) => state.radios.countryCode;
export const selectSearchQuery = (state: RootState) => state.radios.nameQuery;
export const selectCountryName = (state: RootState) => countriesMapping.find((country) => country.iso_3166_1 === state.radios.countryCode)?.name || "";
export const updateUserId = createAction<string>("radios/updateUserId");
export const updateUserSource = createAction<string>("radios/updateUserSource");
export const updateNameQuery = createAction<string>("radios/updateNameQuery");
export const updateCountryCode = createAction<string>("radios/updateCountryCode");
export const incrementOffset = createAction("radios/incrementOffset");
export const resetOffset = createAction("radios/resetOffset");
export const addRadioToFavorite = createAction<Radio>("radios/addRadioToFavorite");
export const removeRadioFromFavorite = createAction<string>("radios/removeRadioFromFavorite");
export const setFavoriteRadios = createAction<Radio[]>("radios/setFavoriteRadios");


export default radiosSlice.reducer;