export const topCountries = [
    {
        "name": "Argentina",
        "iso_3166_1": "AR"
    },
    {
        "name": "Australia",
        "iso_3166_1": "AU"
    },
    {
        "name": "Austria",
        "iso_3166_1": "AT"
    },
    {
        "name": "Belgium",
        "iso_3166_1": "BE"
    },
    {
        "name": "Brazil",
        "iso_3166_1": "BR"
    },
    {
        "name": "Bulgaria",
        "iso_3166_1": "BG"
    },
    {
        "name": "Canada",
        "iso_3166_1": "CA"
    },
    {
        "name": "Chile",
        "iso_3166_1": "CL"
    },
    {
        "name": "China",
        "iso_3166_1": "CN"
    },
    {
        "name": "Colombia",
        "iso_3166_1": "CO"
    },
    {
        "name": "Croatia",
        "iso_3166_1": "HR"
    },
    {
        "name": "Czechia",
        "iso_3166_1": "CZ"
    },
    {
        "name": "Denmark",
        "iso_3166_1": "DK"
    },
    {
        "name": "France",
        "iso_3166_1": "FR"
    },
    {
        "name": "Germany",
        "iso_3166_1": "DE"
    },
    {
        "name": "Greece",
        "iso_3166_1": "GR"
    },
    {
        "name": "Hungary",
        "iso_3166_1": "HU"
    },
    {
        "name": "India",
        "iso_3166_1": "IN"
    },
    {
        "name": "Indonesia",
        "iso_3166_1": "ID"
    },
    {
        "name": "Italy",
        "iso_3166_1": "IT"
    },
    {
        "name": "Mexico",
        "iso_3166_1": "MX"
    },
    {
        "name": "Peru",
        "iso_3166_1": "PE"
    },
    {
        "name": "Poland",
        "iso_3166_1": "PL"
    },
    {
        "name": "Portugal",
        "iso_3166_1": "PT"
    },
    {
        "name": "Romania",
        "iso_3166_1": "RO"
    },
    {
        "name": "Serbia",
        "iso_3166_1": "RS"
    },
    {
        "name": "Spain",
        "iso_3166_1": "ES"
    },
    {
        "name": "Switzerland",
        "iso_3166_1": "CH"
    },
    {
        "name": "The Netherlands",
        "iso_3166_1": "NL"
    },
    {
        "name": "The Philippines",
        "iso_3166_1": "PH"
    },
    {
        "name": "Russia",
        "iso_3166_1": "RU"
    },
    {
        "name": "United Kingdom",
        "iso_3166_1": "GB"
    },
    {
        "name": "United States Of America",
        "iso_3166_1": "US"
    },
    {
        "name": "Turkey",
        "iso_3166_1": "TR"
    },
    {
        "name": "Uganda",
        "iso_3166_1": "UG"
    }
];