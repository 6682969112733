import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "../styles/SearchByCountry.css";

//import { getTopCountries } from "../services/radiosService";
import { topCountries } from "../staticData/topCountries";
import { countriesMapping } from "../staticData/countriesMapping";

import { useDispatch, useSelector } from "react-redux";
import { updateCountryCode, selectCountryCode } from "../store/radios";

interface IProps {
    country: string;
}

export const SearchByCountry = ({country}: IProps) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [allCountries, setAllCountries] = useState<any[]>(topCountries);
    const [filteredCountries, setFilteredCountries] = useState<any[]>(topCountries);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryClicked, setCountryClicked] = useState(false);

    const currentCountryCode = useSelector(selectCountryCode);
    const [currentCountryName, setCurrentCountryName] = useState(countriesMapping.find((country) => country.iso_3166_1 === currentCountryCode)?.name || "");

    // When search term changes
    useEffect(() => {
        if (searchTerm === "") {
            setFilteredCountries(allCountries);
        } else {
            setFilteredCountries(allCountries.filter((country) => {
                return country.name.toLowerCase().includes(searchTerm.toLowerCase());
            }));
            if(countryClicked === false){
                setIsOpen(true);
            }
            setCountryClicked(false);            
        }
    }, [searchTerm]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectCountry = (country: any) => {
        dispatch(updateCountryCode(country.iso_3166_1));
        setCurrentCountryName(country.name);
        setSelectedCountry("");
        setSearchTerm("");
        setIsOpen(false);
        setCountryClicked(true);

        // Update url path
        window.history.pushState({}, "", "/" + country.name.toLowerCase().replaceAll(" ", ""));
    };

    return (
        <div className="relative mobile-country-search">
            <input
                type="text"
                className="border-none outline-none input input-background input-text text-white placeholder-white py-2 pl-3 pr-10 rounded-md cursor-pointer flex items-center justify-between"
                placeholder={currentCountryName || "Filter by Country"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={toggleDropdown}
            />
            {isOpen && (
                <div className="absolute top-12 left-0 right-0 bg-white border-none rounded-md  z-10">
                {filteredCountries.map((country) => (
                    <div
                        key={country.iso_3166_1}
                        className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSelectCountry(country)}
                    >
                        {country.name}
                    </div>
                ))}
                </div>
            )}
        </div>
    );
};