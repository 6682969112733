export const getUserGoogleProfile = (authToken: any) => {
    return fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${authToken.access_token}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authToken.access_token}`,
                    Accept: 'application/json'
                }
            })
    .then((response) => {
        return response.json()
    })
}