const baseUrl = process.env.NODE_ENV === "production" ? "" : "http://localhost:7071";

// This is used to cancel the previous request when a new one is made
let currentRadiosRequest: AbortController | null = null;
let currentRadiosLengthRequest: AbortController | null = null;

export function getRadios(textInput: string, countryCode: string, pageSize: string, offset: string) {
    const controller = new AbortController();
    const signal = controller.signal;

    // Abort previous requests
    if (currentRadiosRequest) {
        currentRadiosRequest.abort();
    }

    currentRadiosRequest = controller;
    
    return fetch(baseUrl + `/api/getRadiosApi?textInput=${textInput}&country=${countryCode}&pageSize=${pageSize}&offset=${offset}`, {signal})
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.error(error);
            return []
        });
}

export function getRadiosByIds(ids: string[]) {
    const controller = new AbortController();
    const signal = controller.signal;

    return fetch(baseUrl + `/api/getRadiosByIds?ids=${ids}`)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.error(error);
            return []
        });
}

export function getRadiosLength(textInput: string, countryCode: string) {
    const controller = new AbortController();
    const signal = controller.signal;

    // Abort previous requests
    if (currentRadiosLengthRequest) {
        currentRadiosLengthRequest.abort();
    }

    currentRadiosLengthRequest = controller;

    return fetch(baseUrl + `/api/getRadiosApi?textInput=${textInput}&country=${countryCode}&lengthOnly=true`)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            //console.log(data);
            return data[0]
        })
        .catch((error) => {
            console.error(error);
            return 0
        });
}

export const getRadiosFromUserFavorites = async (userId: string, userSource: string) => {
    const controller = new AbortController();
    const signal = controller.signal;

    return fetch(baseUrl + `/api/getUserFavoriteRadios?userId=${userId}&userSource=${userSource}`)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            //console.log(data);
            return data
        })
        .catch((error) => {
            console.error(error);
            return []
        });
}

export const addRadioToUserFavorites = async (userId: string, userSource: string, radioId: string) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
            userSource: userSource,
            radioId: radioId
        })
    };

    return fetch(baseUrl + `/api/addUserFavoriteRadio`, options)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            //console.log(data);
            return data
        })
        .catch((error) => {
            console.error(error);
            return error
        });
}

export const removeRadioFromUserFavorites = async (userId: string, userSource: string, radioId: string) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
            userSource: userSource,
            radioId: radioId
        })
    };

    return fetch(baseUrl + `/api/removeUserFavoriteRadio`, options)
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.error(error);
            return error
        });
}