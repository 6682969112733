import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const instrumentationKey = "aee5399b-c5af-4b03-9cbb-a212f3381b09"

const appInsights = new ApplicationInsights({
    config: {
    instrumentationKey: instrumentationKey,
    },
});
appInsights.loadAppInsights();

export const logError = (error: Error, customMessage?: string) => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: " + error)
        return
    }

    appInsights.trackException({exception: error, properties: {
        customMessage: customMessage
    }});
}

export const logRadioClick = (radioId: string, radioName: string, country: string) => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: Radio clicked", radioId, radioName, country)
        return
    }

    appInsights.trackEvent({name: "Radio clicked", properties: {
        radioId: radioId,
        radioName: radioName,
        country: country
    }});
}

export const pageView = (pageName: string) => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: Page view", pageName)
        return
    }
    
    appInsights.trackPageView({name: pageName});
}

export const logGoogleLogin = (userId: string, userName: string) => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: Google login", userId, userName)
        return
    }

    appInsights.trackEvent({name: "Google login", properties: {
        userId: userId,
        userName: userName
    }});
}

export const logAddRadioToFavorite = (radioId: string, radioName: string, countryCode: string, country: string) => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: Add radio to favorite", radioId, radioName, countryCode, country)
        return
    }

    appInsights.trackEvent({name: "Add radio to favorite", properties: {
        radioId: radioId,
        radioName: radioName,
        countryCode: countryCode,
        country: country
    }});
}

export const logRemoveRadioFromFavorite = (radioId: string, radioName: string, countryCode: string, country: string) => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: Remove radio from favorite", radioId, radioName, countryCode, country)
        return
    }

    appInsights.trackEvent({name: "Remove radio from favorite", properties: {
        radioId: radioId,
        radioName: radioName,
        countryCode: countryCode,
        country: country
    }});
}

export const logFeedbackSubmit = () => {
    if (process.env.NODE_ENV == 'development') {
        console.log("LOCAL_DEV_LOG: Feedback submitted!")
        return
    }

    appInsights.trackEvent({name: "User feedback"})
}