// React dependencies
import { useEffect, useLayoutEffect, useState } from "react";

// Project components
import { RadiosList } from "../lists/RadiosList";
import { SearchByCountry } from "../filterComponents/SearchByCountry";
import { SearchByName } from "../filterComponents/SearchByName";

// Redux store
import { useSelector } from "react-redux";
import { selectCountryCode, selectSearchQuery } from "../store/radios";

// Component styles
import "../styles/Radios.css"

// Static data
import { countriesMapping } from "../staticData/countriesMapping";

// Services
import { getRadiosLength } from "../services/radioGlobalApi";
import { pageView } from "../services/appInsightsService";

// Get url dynamic path (country filter)
import { useParams } from "react-router-dom";

export const Radios = () => {
  const currentCountryCode = useSelector(selectCountryCode);
  const currentSearchQuery = useSelector(selectSearchQuery);

  const [radiosLength, setRadiosLength] = useState(0);

  const {countryFilter} = useParams();

  const getCountryName = () => {
    return countriesMapping.find((country) => country.iso_3166_1 === currentCountryCode)?.name || "";
  }
  
  // Log page view to app insights
  useEffect(() => {
    pageView("Radios");
  }, [])

  // Update number of radios for current filters
  useLayoutEffect(() => {
      getRadiosLength(currentSearchQuery, currentCountryCode)
      .then((res) => {
        setRadiosLength(res);
      });
  }, [currentSearchQuery, currentCountryCode])
  
  
  return (
    <div className="flex flex-col">
      <div className="flex main-container justify-between">
        <div className="flex flex-row flex-nowrap pb-4 mobile-search">
          <SearchByName />
          <SearchByCountry country={countryFilter || ""}/>
        </div>
        <div className="flex titles-container pl-4">
          <span className="radios-title">{currentCountryCode ? "Top Radio Stations in " + getCountryName() : "Listen to global radio stations for free with myRadio"}</span>
          <span className="radios-subtitle">{radiosLength + " radio stations found"}</span>
        </div>
        <div className="country-selector desktop-country-search">
          <SearchByCountry country={countryFilter || ""}/>
        </div>
      </div>
      <RadiosList />
    </div>
  );
};