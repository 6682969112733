// React imports
import { useState } from "react";

// Layout specific styles
import "../styles/MainLayout.css";

// Page routing dependencies
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages
import { Radios } from "../pages/Radios";
import { About } from "../pages/About";
import { Favorites } from "../pages/Favorites";

// Store dependencies
import { useDispatch, useSelector } from "react-redux";
import { selectPlayerStatus } from "../store/audioPlayer";

// Project components
import { SearchByName } from "../filterComponents/SearchByName";
import { RadioPlayerSmall } from "../components/RadioPlayerSmall";
import { FeedbackButton } from "../components/FeedbackButton";
import { NavBar } from "../components/Navbar";

// Wrappers
import { FavoriteRadiosSync } from "./FavoriteRadiosSync";

// Icons
import { GiHamburgerMenu } from "react-icons/gi";



export const MainLayout = () => {
  const status = useSelector(selectPlayerStatus);
  const [navOpen, setNavOpen] = useState(false);

  return (
    <FavoriteRadiosSync>
        <Router>
            <div className="flex h-full layout">
                <div className="w-96 bg-white flex-col left-side fixed top-0">
                    <div className="mb-4 flex-1 h-1/4 flex-col p-8 sidebar-first-section" >
                        {/* Left section goes here */}
                        <div className="w-full">
                            <img src="/main-logo.jfif" alt="myRadio logo" className="w-56 block mx-auto mb-16" />
                        </div>
                        
                        <div className="flex flex-col items-center">
                            <NavBar newItemSelected={() => null}/>
                            <div className={status == "idle" ? "feedback-button" : "feedback-button-mb100" }>
                                <FeedbackButton />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex flex-col">
                    <div className="py-4 search-by-name fixed top-0 flex flex-row">
                        {/* Top section goes here */}
                        <div className="w-96 ml-4 desktop-name-search">
                            <SearchByName />  
                        </div>
                        <div className="w-96 ml-4 mobile-header">
                            <img src="/main-logo.jfif" alt="myRadio logo" className="w-36 block mb-16" /> 
                        </div>
                        <div className="mobile-header">
                            <GiHamburgerMenu className="hamburger-icon" size={20} color="#ef4345" onClick={() => setNavOpen(!navOpen)}/>
                        </div>
                    </div>
                    <div className="mb-4 bg-neutral-100 flex-1 content">
                        {/* Main content goes here */}
                        <Routes>
                            <Route path="/favorites" element={<Favorites />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/:countryFilter" element={<Radios />} />
                            <Route path="/" element={<Radios />} />
                        </Routes>
                    </div>
                </div>
                <div>
                    {/* Dynamic radio player at bottom of page */}
                        <RadioPlayerSmall />
                </div>
            </div>
            <div className={`grey-block ${navOpen ? 'grey-block-open' : ''}`} onClick={() => setNavOpen(!navOpen)}/>
            <div className={`nav-menu ${navOpen ? 'nav-open' : ''}`}>
                <div className="navbar-content flex flex-col h-full justify-between">
                    <div>
                        <NavBar newItemSelected={() => setNavOpen(!navOpen)}/>
                    </div>
                    <div className=" mb-32 w-full pr-6 h-10">
                        <FeedbackButton />
                    </div>
                    
                </div>
            </div>
        </Router>
    </FavoriteRadiosSync>
  );
};