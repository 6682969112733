// React hooks
import { useEffect, useState, createRef } from "react";

// Styles
import "../styles/RadioSquare.css";
import "../styles/RadioPlayerSmall.css";

// Redux store
import { useDispatch, useSelector } from "react-redux";
import { 
  updateSelectedRadio, 
  selectPlayerStreamId,
  selectPlayerStreamTitle,
  selectPlayerStreamUrl,
  selectPlayerStatus,
  selectPlayerStreamImage,
  setPlayerStatusPlaying,
  setPlayerStatusPaused } from "../store/audioPlayer";
import { 
  selectAllRadios,
  selectFavoriteRadios,
  selectCountryName,
  selectCountryCode,
  selectUserId,
  selectUserSource,
  addRadioToFavorite,
  removeRadioFromFavorite } from "../store/radios";

// Services
import audioPlayer from "../services/audioPlayer";
import { addRadioToUserFavorites, removeRadioFromUserFavorites } from "../services/radioGlobalApi";

// Icons
import { 
  FaRegStar,
  FaStar,
  FaPause,
  FaPlay,
  FaForward,
  FaBackward,
  FaVolumeUp } from 'react-icons/fa';

// Routing dependencies
import { useNavigate } from "react-router-dom";

// Logging
import { logAddRadioToFavorite, logError, logRemoveRadioFromFavorite } from "../services/appInsightsService";

// Interfaces
import { Radio } from "../interfaces/Radio"

export const RadioPlayerSmall = () => {
  const dispatch = useDispatch<any>();
  const currentRadiosList = useSelector(selectAllRadios);
  const stateStreamId = useSelector(selectPlayerStreamId);
  const stateStreamTitle = useSelector(selectPlayerStreamTitle);
  const stateStreamUrl = useSelector(selectPlayerStreamUrl);
  const statePlayerStatus = useSelector(selectPlayerStatus);
  const stateStreamImage = useSelector(selectPlayerStreamImage);
  const favoriteRadios = useSelector(selectFavoriteRadios);
  const stateStreamCountryName = useSelector(selectCountryName);
  const stateStreamCountryCode = useSelector(selectCountryCode);
  const userId = useSelector(selectUserId);
  const userSource = useSelector(selectUserSource);

  const [isPlaying, setIsPlaying] = useState(false);
  const [ loadingStream, setLoadingStream ] = useState(false);
  const [ favorite, setFavorite ] = useState(false);
  

  const [player, setPlayer] = useState<audioPlayer>(new audioPlayer());
  const audioRef = createRef<HTMLAudioElement>();

  // Volume controls
  const [volume, setVolume] = useState(localStorage.getItem("rplayer-volume") ? Number(localStorage.getItem("rplayer-volume")) : 0.8)
  const [muted, setMuted] = useState(false)
  const finalVolume = muted ? 0 : volume ** 2

  // Initialize object to allow navigation to other pages
  const navigate = useNavigate();

  useEffect(() => {
    // Update favorite icon
    setFavorite(favoriteRadios.some((radio: Radio) => radio.id === stateStreamId))

    switch(statePlayerStatus) {
      case "playing":
        if(stateStreamUrl){
          player.playSrc(stateStreamUrl)
          .then(() => {
            setLoadingStream(false);
            setIsPlaying(true);
            dispatch(setPlayerStatusPlaying());
          })
        }
        break;
      case "paused":
        player.pause();
        setIsPlaying(false);
        dispatch(setPlayerStatusPaused());
        break;
      case "loading":
        setLoadingStream(true);
        if(stateStreamUrl){
          player.playSrc(stateStreamUrl)
          .then(() => {
            setIsPlaying(true);
            dispatch(setPlayerStatusPlaying());
            setLoadingStream(false);
          })
        }
        break;
      default:
        setIsPlaying(false);
    }
  }, [stateStreamId, statePlayerStatus, dispatch]);

  const togglePlay = () => {
    isPlaying ? player.pause() : player.play();
    isPlaying ? dispatch(setPlayerStatusPaused()) : dispatch(setPlayerStatusPlaying());
    setIsPlaying(!isPlaying);
  };

  const addToFavorites = () => {
    if (userId && userSource) {
      addRadioToUserFavorites(userId, userSource, stateStreamId)
      .then(() => {
        dispatch(addRadioToFavorite({
          name: stateStreamTitle,
          id: stateStreamId,
          url: stateStreamUrl,
          favicon: stateStreamImage,
          country: "",
          countryCode: "",
          state: "",
          urlResolved: "",
          clickCount: 0
        }));
        logAddRadioToFavorite(stateStreamId, stateStreamTitle, stateStreamCountryCode, stateStreamCountryName);
        setFavorite(true);
      })
      .catch((error: Error) => {
        logError(error, "Error adding radio to favorites")
      });
    }
    else {
      // User is not logged in, redirect him to log in page
      navigate("/favorites");
    }
  }

  const removeFromFavorites = () => {
    if (userId && userSource) {
      removeRadioFromUserFavorites(userId, userSource, stateStreamId)
      .then(() => {
        dispatch(removeRadioFromFavorite(stateStreamId));
        logRemoveRadioFromFavorite(stateStreamId, stateStreamTitle, stateStreamCountryCode, stateStreamCountryName);
        setFavorite(false);
      })
      .catch((error: Error) => {
        logError(error, "Error removing radio from favorites")
      });
    }
    else {
      // This scenario should't happen
      // User is not logged in, redirect him to log in page
      navigate("/favorites");
    }
  }

  const getAdjacentRadio = (direction: string) => {
    const radioIndex = currentRadiosList.findIndex((radio: Radio) => radio.id === stateStreamId);
    let adjacentRadio: Radio;
    if (direction === "next") {
        if (radioIndex === currentRadiosList.length - 1) {
            adjacentRadio = currentRadiosList[0];
        } else {
            adjacentRadio = currentRadiosList[radioIndex + 1];
        }
    } else {
        if (radioIndex === 0) {
            adjacentRadio = currentRadiosList[currentRadiosList.length - 1];
        } else {
            adjacentRadio = currentRadiosList[radioIndex - 1];
        }
    }
    dispatch(updateSelectedRadio({
      streamId: adjacentRadio.id,
      streamTitle: adjacentRadio.name,
      streamUrl: adjacentRadio.url,
      streamImage: adjacentRadio.favicon,
      status: "loading"
    }));
  }

  // If no radio has been selected, don't show player
  if(stateStreamId === "") {
    return (
      <div className="flex">
      </div>
    );
  }
  
  return (
    <div className="flex justify-between items-center h-20 bg-white border-t-2 p-4 bottom-bar custom-shadow">
      <div className="flex flex-row items-center radio-img-and-name grow">
        <img 
          className="w-8" 
          src={stateStreamImage ? "https://radioicons.azureedge.net/radio-logos-v2/" + stateStreamId + "." + stateStreamImage.split("?")[0].split(".")[stateStreamImage.split("?")[0].split(".").length - 1] : "radio_default.jpg"} 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="radio_default.jpg";
          }}
          alt={""} />
        <div className="px-4 radio-name">{stateStreamTitle}</div>
        {favorite ? (
                  <FaStar title="Add to favorites" size={20} className="text-yellow-500 mr-1 mb-1 favorite-star-icon" onClick={() => removeFromFavorites()} />
        ) : (
                  <FaRegStar title="Remove from favorites" size={20} className="text-yellow-500 mr-1 mb-1 favorite-star-icon" onClick={() => addToFavorites()} />
        )}
      </div>

      <div className="flex flex-row items-center grow-0">
        <div onClick={() => getAdjacentRadio("previous")} className="flex cursor-pointer w-10 h-10 justify-center items-center hide-on-mobile">
          <FaBackward />
        </div>
        <div className="flex items-center play-pause-button">
          {isPlaying ? (
            <div onClick={togglePlay} className="flex items-center justify-center first-letter rounded-full play-pause-container cursor-pointer">
              <FaPause />
            </div>
          ) : (
            <div onClick={togglePlay} className="flex items-center justify-center first-letter rounded-full play-pause-container cursor-pointer">
              <FaPlay />
            </div>
          )}
        </div>
        <div onClick={() => getAdjacentRadio("next")} className="flex cursor-pointer w-10 h-10 justify-center items-center hide-on-mobile">
          <FaForward />
        </div>
      </div>

      <div className="flex flex-row items-center grow justify-end hide-on-mobile">
        <div className="mr-2">
          <FaVolumeUp size={"16px"} color="#6B7280"/>
        </div>
        <input
          className="volume-slider"
          type="range"
          min={0}
          max={1}
          step={0.02}
          value={volume}
          onChange={event => {
            player.setVolume(Number(event.target.value))
            setVolume(Number(event.target.value))
          }}
          id="volume-slider"
          aria-label="Volume"
          color="red"
        />
      </div>
    </div>
  );
};