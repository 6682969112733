import { createAsyncThunk, createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface PlayerState {
    status: "playing" | "paused" | "loading" | "idle" | "error";
    streamUrl: string;
    streamTitle: string;
    streamId: string;
    streamImage: string;
}

const initialState: PlayerState = {
    status: "idle",
    streamUrl: "",
    streamTitle: "",
    streamId: "",
    streamImage: ""
};

export const playerSlice = createSlice({
    name: "player",
    initialState,
    reducers: {
        // reducer for updating currently selected radio
        updateSelectedRadio: (state, action: PayloadAction<PlayerState>) => {
            //console.log("updateSelectedRadio action.payload", action.payload);
            state.streamUrl = action.payload.streamUrl;
            state.streamTitle = action.payload.streamTitle;
            state.streamId = action.payload.streamId;
            state.streamImage = action.payload.streamImage;
            state.status = action.payload.status;
            //console.log("updateSelectedRadio state", state);
        },
        setPlayerStatusPlaying: (state) => {
            state.status = "playing";
        },
        setPlayerStatusPaused: (state) => {
            state.status = "paused";
        },
        setPlayerStatusLoading: (state) => {
            //console.log("setPlayerStatusLoading", state);
            state.status = "loading";
        },
        setPlayerStatusIdle: (state) => {
            state.status = "idle";
        },
        setPlayerStatusError: (state) => {
            state.status = "error";
        }
    }
});

export const selectPlayerStatus = (state: RootState) => state.player.status;
export const selectPlayerStreamUrl = (state: RootState) => state.player.streamUrl;
export const selectPlayerStreamTitle = (state: RootState) => state.player.streamTitle;
export const selectPlayerStreamId = (state: RootState) => state.player.streamId;
export const selectPlayerStreamImage = (state: RootState) => state.player.streamImage;

export const { 
    updateSelectedRadio,
    setPlayerStatusPlaying,
    setPlayerStatusPaused,
    setPlayerStatusLoading,
    setPlayerStatusIdle,
    setPlayerStatusError
} = playerSlice.actions;

export default playerSlice.reducer;