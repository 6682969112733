// React hooks
import { useEffect, useState } from "react";

// Project components
import { RadioSquare } from "../components/RadioSquare";

// Redux store
import { useDispatch, useSelector } from "react-redux";
import { selectFavoriteRadios } from "../store/radios";

// Component styles
import "../styles/RadiosList.css";

export const RadiosListFavorites = () => {
  const dispatch = useDispatch<any>();
  const favoriteRadiosStoreData = useSelector(selectFavoriteRadios);

  const [ radiosList, setRadiosList ] = useState<any>(favoriteRadiosStoreData);

  // Runs when favoriteRadiosStoreData changes
  useEffect(() => {
    setRadiosList(favoriteRadiosStoreData);
  }, [favoriteRadiosStoreData, dispatch]);

  // If no radios found
  if (radiosList.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold">No favorite radios found</h2>
          <p className="text-lg">Add radios to your favorites by clicking on the star icon</p>
        </div>
      </div>
    );
  }

  // Radios found
  return (
    <>
      <div className="radios-grid">
        {radiosList.map((radio: any, index: number) => {
          if (radiosList.length === index + 1) {
            return (
              <div className="square mobile-flex-item" key={index}>
                <RadioSquare radio={radio} />
              </div>
            );
          } else {
            return (
              <div className="square mobile-flex-item" key={index}>
                <RadioSquare radio={radio} />
              </div>
            );
          }
        })}
      </div>
    </>
  );
};