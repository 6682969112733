import { useEffect, useState } from "react";
import "../styles/RadioSquare.css";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedRadio, selectPlayerStreamId, selectPlayerStatus } from "../store/audioPlayer";
import { ScaleLoader } from 'react-spinners';
import { logRadioClick } from "../services/appInsightsService";

interface Radio {
  name: string,
  country: string,
  countryCode: string,
  state: string,
  id: string,
  url: string,
  urlResolved: string,
  clickCount: number,
  favicon: string
}

interface IProps {
    radio: Radio;
}

export const RadioSquare = ({ radio }: IProps) => {
  const dispatch = useDispatch<any>();
  const playerState = useSelector((state: RootState) => state.player);
  const playerStatus = useSelector(selectPlayerStatus)
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadingStream, setLoadingStream] = useState(false);
  const [ errorLoadingStream, setErrorLoadingStream ] = useState(false);

  const [firstTimeLoading, setFirstTimeLoading] = useState(true);

  useEffect(() => {
    //console.log("radio square playerStatus: " + playerStatus);
    // Get stream id from state
    if(playerState.streamId !== radio.id) {
      setIsPlaying(false);
      setLoadingStream(false);
      setFirstTimeLoading(true);
    }
    if(playerState.streamId === radio.id) {
      //console.log("triggered playerState useEffect in RadioSquare");
      

      switch(playerStatus) {
        case "playing":
          setIsPlaying(true);
          setLoadingStream(false);
          setErrorLoadingStream(false);
          break;
        case "paused":
          setIsPlaying(false);
          setLoadingStream(false);
          setErrorLoadingStream(false);
          break;
        case "loading":
          setLoadingStream(true);
          setErrorLoadingStream(false);
          break;
        case "error":
          setLoadingStream(false);
          setErrorLoadingStream(true);
          break;
      }
    }
  }, [playerState, dispatch]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    //isPlaying ? console.log("Stopped playing " + radio.name) : console.log("Started playing " + radio.name);

    if (firstTimeLoading) {
      setLoadingStream(true);
      setFirstTimeLoading(false);
      dispatch(updateSelectedRadio({
        streamId: radio.id,
        streamTitle: radio.name,
        streamUrl: radio.url,
        streamImage: radio.favicon,
        status: "loading"
      }));
    }

    else {
      dispatch(updateSelectedRadio({
        streamId: radio.id,
        streamTitle: radio.name,
        streamUrl: radio.url,
        streamImage: radio.favicon,
        status: isPlaying ? "paused" : "loading"
      }))
    }

    // Log radio click to App Insights
    if (!isPlaying) {
      logRadioClick(radio.id, radio.name, radio.country);
    }

  };

  // If stream is selected but sound is loading, show loading animation
  if(loadingStream) {
    return (
      <div onClick={togglePlay}>
        <div className="loading-spinner selected-image bg-white">
          <ScaleLoader className="" color={'#ef4444'} loading={true} />
        </div>
        <div className="name">{radio.url.includes("m3u8") ? radio.name + " (m3u8)"  : radio.name}</div>
      </div>
    );
  }

  if(errorLoadingStream) {
    return (
      <div onClick={togglePlay}>
        <img 
          className={isPlaying ? 'selected-image image-error' : 'image-error'} 
          src={"error.png"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="radio_default.jpg";
          }}
          alt={"Image of " + radio.name} />
        <div className="name">{radio.url.includes("m3u8") ? radio.name + " (m3u8)"  : radio.name}</div>
      </div>
    );
  }
          
  
  return (
    <div onClick={togglePlay}>
      <img 
        className={isPlaying ? 'selected-image image' : 'image'}
        src={radio.favicon ? "https://radioicons.azureedge.net/radio-logos-v2/" + radio.id + "." + radio.favicon.split("?")[0].split(".")[radio.favicon.split("?")[0].split(".").length - 1] : "radio_default.jpg"}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src="radio_default.jpg";
        }}
        loading="lazy"
        alt={"Image of " + radio.name} />
      <div className="name">{radio.url.includes("m3u8") ? radio.name + " (m3u8)"  : radio.name}</div>
    </div>
  );
};