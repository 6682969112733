// React dependencies
import React from "react";

// Redux store
import { useDispatch, useSelector } from "react-redux";
import { updateNameQuery, selectCountryCode } from "../store/radios";

// Icons
import { FaSearch } from "react-icons/fa";

// Static data
import { countriesMapping } from "../staticData/countriesMapping";

// Component styles
import "../styles/SearchByName.css";

export const SearchByName = () => {
  const dispatch = useDispatch();
  const countryCode = useSelector(selectCountryCode);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    dispatch(updateNameQuery(query));
  }

  return (
    <div className="relative mobile-name-search">
      <input
        type="text"
        placeholder={"Radio Stations in " + countriesMapping.find((country) => country.iso_3166_1 === countryCode)?.name}
        className="border outline-none border-gray-300 py-2 pl-10 pr-4 rounded-full w-full input-mobile"
        onChange={handleInputChange}
      />
      <FaSearch className="absolute left-3 top-3 text-gray-400" />
    </div>
  );
};