// React dependencies
import React, { useState } from "react";

// Component style
import "../styles/FeedbackButton.css";

// Service dependencies
import { submitFeedback } from "../services/feedback";

// Logging service
import { logError } from "../services/appInsightsService"

export const FeedbackButton = () => {
    const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
    const [feedbackSubmitLoading, setFeedbackSubmitLoading] = useState<boolean>(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [feedbackError, setFeedbackError] = useState(false);

    const handleFeedbackClick = () => {
        setShowFeedbackDialog(true);
    };
    
    const handleFeedbackSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFeedbackSubmitLoading(true);
        submitFeedback(event.currentTarget.feedback.value, event.currentTarget.email.value)
            .then(() => {
                setFeedbackSubmitted(true);
                setFeedbackError(false);
            })
            .catch((error: Error) => {
                logError(error);
                setFeedbackSubmitted(false);
                setFeedbackError(true);
            })
            .finally(() => {
                setFeedbackSubmitLoading(false);
                setShowFeedbackDialog(false);
            });
    };
    
    const handleFeedbackClose = () => {
        setFeedbackSubmitted(false);
        setFeedbackError(false);
    };

    const handleFeedbackCancel = () => {
        setShowFeedbackDialog(false);
    };

    return (
        <>
            <button 
                className="border-2 border-red-500 text-red-500 px-4 rounded-lg w-full h-full"
                onClick={handleFeedbackClick}
            >
                Feedback
            </button>
            {showFeedbackDialog && (
                <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                    <form
                        className="bg-white p-6 rounded-lg w-screen m-4 sm:m-16 md:w-2/5"
                        onSubmit={handleFeedbackSubmit}
                    >
                        <h2 className="text-lg font-medium mb-4 title">Let us know what your think! </h2>
                        <p className="sub-text mb-4">This feedback will be sent directly to our team to continue to improve this radio streaming service. If you'd like a response, please provide your email. Thank you!</p>
                        <label className="block mb-2" htmlFor="feedback">
                            Your email (optional):
                        </label>
                        <input
                            className="border border-gray-400 p-2 mb-4 w-full"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="you@email.com"
                        />
                        <label className="block mb-2" htmlFor="feedback">
                            Feedback:
                        </label>
                        <textarea
                            className="border border-gray-400 p-2 mb-4 w-full"
                            id="feedback"
                            name="feedback"
                            rows={4}
                            required
                        />
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                                type="button"
                                onClick={handleFeedbackCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {feedbackSubmitLoading && (
                <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                    <div className="flex items-center justify-center mt-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    </div>
                </div>
            )}
            {feedbackSubmitted && !feedbackSubmitLoading && (
                <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg m-4 sm:m-16 md:w-2/5">
                        <h2 className="text-lg font-medium mb-4 title">Feedback submitted</h2>
                        <p className="sub-text-output">Thank you for your feedback, we appreciate you reaching out to us!</p>
                        <button
                            className="bg-red-500 text-white px-4 py-2 rounded-lg mt-4"
                            onClick={handleFeedbackClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            {feedbackError && !feedbackSubmitLoading && (
                <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg m-4 sm:m-16 md:w-2/5">
                        <h2 className="text-lg font-medium mb-4 title">Oops</h2>
                        <p className="sub-text-output">There was an error submitting your feedback. Please try again later.</p>
                        <button
                            className="bg-red-500 text-white px-4 py-2 rounded-lg mt-4"
                            onClick={handleFeedbackClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};