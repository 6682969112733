// Logging service
import { logFeedbackSubmit, logError } from "./appInsightsService"

const baseUrl = process.env.NODE_ENV === "production" ? "" : "http://localhost:7071";

export const submitFeedback = (feedback: string, userEmail?: string) => {

    return fetch(`${baseUrl}/api/sendFeedbackEmail`, {
        method: "POST",
        body: JSON.stringify({
                from: userEmail || "unknown",
                text: feedback
            }),
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then(() => {
        logFeedbackSubmit()
    })
    .catch((error: Error) => {
        logError(error, "Failed to submit user feedback")
        throw error
    })
}