import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
    userImage: string;
    userDisplayName: string;
}

export const UserAvatar = ({userImage, userDisplayName} : IProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Initialize object to allow navigation to other pages
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear()
        navigate('/');
    }

    return (
        <>
            <img src={userImage} onClick={() => setIsMenuOpen(!isMenuOpen)} alt="User avatar" className="w-16 h-16 rounded-full overflow-hidden cursor-pointer" />
            {
                isMenuOpen && (
                    <div className="absolute top-30 right-10 mt-16 w-48 bg-white rounded-md shadow-xl z-10">
                        <ul>
                            <li className="px-4 py-2 hover:bg-gray-100 cursor-default" onClick={() => setIsMenuOpen(false)}>
                                {userDisplayName}
                            </li>
                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => logout()}>
                                Logout
                            </li>
                        </ul>
                    </div>
                )
            }
        </>
    )

}