import hls from "hls.js";

export default class rPlayer extends Audio {
    hls: hls | null = null;
    firstTimeSetup = true;
    constructor() {
        super();

        const key = 'rplayer-volume';
        this.hls = null;

        if (localStorage.hasOwnProperty(key)) {
            this.volume = Number(localStorage.getItem(key));
        } else {
            this.volume = 1;
        }
    }

    /**
     * 
     * @param {number} seconds 
     */
    rewind(seconds: number) {
        this.currentTime = this.currentTime - seconds;
    };

    /**
     * 
     * @param {string} src 
     */
    async playSrc(src: string) {
        //console.log('playSrc', src);
        const isHls = src.indexOf('.m3u8') > 0;

        this.stop();

        return new Promise<void>((resolve, reject) => {
            if (hls.isSupported() && isHls) {
                // Not adding this config will cause .m3u8 sources to not stream continuously
                const config = {
                    lowLatencyMode: false,
                    liveDurationInfinity: true,
                    xhrSetup: function (xhr: any, url: string) {
                        xhr.withCredentials = false;
                        // Setting withCredentials to false prevents the following issue:
                        // Access to XMLHttpRequest at 'https://example.com/playlist.m3u8' from origin 'http://localhost:3000' has been blocked by CORS policy:
                        // The value of the 'Access-Control-Allow-Origin' header in the response must not be the wildcard '*' when the request's credentials mode is 'include'.
                        // The credentials mode of requests initiated by the XMLHttpRequest is controlled by the withCredentials attribute.
                    },
                    // Copied the bellow config from the following link (no idea what I'm doing but it seems to work?):
                    //https://github.com/video-dev/hls.js/issues/3913
                    autoStartLoad: true,
                    startPosition: 0,
                    startLevel: 0,
                    //debug: true, // This will stream logs in the console
                    maxBufferLength: 60,
                    capLevelToPlayerSize: true,
                    maxMaxBufferLength: 600,
                    backBufferLength: Infinity,
                    maxBufferSize: 60 * 1000 * 1000,
                    maxBufferHole: 0.5,
                    maxLoadingDelay: 5,
                    defaultAudioCodec: 'mp4a.40.2',
                    initialLiveManifestSize: 3,
                    liveSyncDurationCount: 9,
                    enableWorker: true,
                    fragLoadingMaxRetry: 10,
                    manifestLoadingMaxRetry: 10,
                    levelLoadingMaxRetry: 10,
                };
                //console.log('hls config', config);
                this.hls = new hls(config);
                this.hls.loadSource(src);
                this.hls.attachMedia(this);
                this.hls.on(hls.Events.MANIFEST_PARSED, async () => {
                    this.play()
                    .then(() => {
                        //console.log('audioPlayer - playing m3u8 source: ', src);
                    })
                    .catch((error) => {
                        console.error(error);
                        throw new Error(error);
                    })
                    .finally(() => {
                        resolve();
                    });
                });
            } else if (this.canPlayType('application/vnd.apple.mpegurl') || (hls.isSupported() && !isHls)) {

                const removeAllEventListeners = () => {
                    this.removeEventListener('playing', playingEventListener);
                    this.removeEventListener('loadedmetadata', loadedMetadataEventListener);
                    this.removeEventListener('error', errorEventListener);
                };

                const playingEventListener = () => {
                    //console.log('audioPlayer - playing non-m3u8 source:', src);
                    //console.log("triggered playing event listener")
                    resolve();
                    // Remove all event listeners
                    removeAllEventListeners();
                };
                const loadedMetadataEventListener = () => {
                    this.play()
                    this.removeEventListener('loadedmetadata', loadedMetadataEventListener);
                };
                const errorEventListener = (error: any) => {
                    console.error("Error loading stream " + src, error);
                    reject(error);
                    // Remove all event listeners
                    removeAllEventListeners();
                };

                this.src = src;

                // Add event listeners
                this.addEventListener('playing', playingEventListener);
                this.addEventListener('loadedmetadata', loadedMetadataEventListener);
                this.addEventListener('error', errorEventListener);
                this.firstTimeSetup = false;
                
            }
        });
    }

    mute() {
        this.muted = !this.muted;
    }

    stop() {
        this.pause();
        this.currentTime = 0;

        if (this.hls) {
            this.hls.destroy();
            this.hls = null;
        }
    }

    upVolume() {
        this.setVolume(this.volume + 0.1);
        localStorage.setItem("rplayer-volume", this.volume.toString());
    }

    downVolume() {
        this.setVolume(this.volume - 0.1);
        localStorage.setItem("rplayer-volume", this.volume.toString());
    }

    /**
     * 
     * @param {decimal} value 
     */
    setVolume(value: number) {
        if (value >= 0.0 && value <= 1.0) {
            this.volume = Number(value);
            localStorage.setItem("rplayer-volume", this.volume.toString());
        }
    }

    get isHls() {
        if (this.hls instanceof hls) return true;

        return false;
    }

    get playing() {
        return this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2;
    }
}