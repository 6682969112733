import { configureStore } from '@reduxjs/toolkit';
import radiosReducer from './radios';
import playerReducer from './audioPlayer';

export const store = configureStore({
  reducer: {
    radios: radiosReducer,
    player: playerReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;